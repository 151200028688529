.createBusiness {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    .left-data {
        margin-top: 32px;
    }
    .container {
        margin: auto;
    }
    .left_form {
        margin-top: 16px;
    }
    .left_side {
        background: #f2f2f2;
        max-width: 340px;
        height: 310vh;
        img {
            margin-top: 30px;
        }
    }

    .content-area {
        padding: 80px 100px;
        background-color: #fff;
        width: 90%;
        margin: auto;
        top: 160px;
        position: absolute;
        left: 0;
        right: 0;
        box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.16);

        .heading {
            font-family: 'Poppins', sans-serif;
            color: #19c1d6;
            font-size: 28px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0px;
            text-align: center;

        }

        .food_type_side {
            padding-right: 65px;

            .food_type_row {
                margin-top: 46px;

                .isoSelectedText{
                    width: 20px;
                    height: 22px;
                    border: 1px dashed #e73d3b;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #e73d3b;
                    font-size: 10px;
                }

                .food_type {
                    .box {
                        cursor: pointer;
                    }
                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: #a3a3a3;
                }

                span {
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.20000000298023224px;
                    color: #19c1d6;
                    cursor: pointer;
                }
            }
        }
    }

    .box {
        width: 94px;
        height: 70px;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;

        img {
            height: 49px;
            width: 49px;
            margin: auto;
        }
    }

    .phone_number {
        margin-top: 24px !important;

        .dropzone_heading {
            margin-bottom: 24px !important;
        }
    }

    .left_form {
        margin-bottom: 16px;

        .form-control {
            background: #f2f2f2;
            border: none;
            width: 100%;
            height: 60px;
            color: #000000;
            padding: 20px;
            border-radius: 4px !important;

        }

        .form-control::placeholder {
            color: #bcbcbc;
        }
    }

    .dropdownList{
        .rw-dropdown-list {
            width: 100%;
            padding: 0;
        }
    }
    
    .input_textarea {
        textarea {

            background-color: #f2f2f2;
            border: none;
            color: #000000;
            margin-top: 30px;
            border-radius: 4px;
            margin-bottom: 12px;
            padding: 20px;
        }

        textarea::placeholder {

            color: #bcbcbc;
        }
    }

    // Dropzone----------------------------
    .dropzone_custom {
        background: red;
        height: 190px;
        width: 452px;
    }

    .searchOrder {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 20%;
        max-width: 250px;
        height: 20%;
        left: 0px;
        top: 0px;
        background: #ffffff;
        border: 2px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .dropzone_heading {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: #bcbcbc;

    }

    .dropzone {
        border: 1px dashed #a3a3a3;
        padding: 62px 0;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
        .update_drop {
            background: #d9d9d9;
            max-width: 120px;
            min-height: 140px;
            margin: 25px 30px;
            font-size: 48px;
            color: #919191;
        }

        p {
            font-size: 0.875rem;
            color: #858585;
            font-weight: 500;

            div {
                display: inline;
                color: #19C1D6;
                font-weight: bold;
            }
        }

        span {
            font-size: 0.75rem;
            color: #858585;
            font-weight: 300;
        }
    }

    .select_days {
        color: #bcbcbc;
    }

    .week {
        .day {
            border: 1px solid #C8CAC7;
            color: #858585;
            text-align: center;
            border-radius: 2px;
            cursor: pointer;
        }

        .localColor {
            background-color: #19C1D6;
            color: #fff;
        }
    }

    .upload_img_field {
        height: 190px;

        img {
            border-radius: 12px;
            border: 1px solid #C8CAC7;
            background-size: cover;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
        }
    }
    .yt-link-text{
        margin-bottom: 190px;
    }
    .check-box-text{
        margin-bottom: 58px;
    }
    .commom_btn {
        width: 603px;
        margin: auto;
    }

    .validate {
        color: #19C1D6 !important;
    }

    .react-tel-input {
        .form-control {
            padding-left: 60px;
        }

        // .flag-dropdown {
        //     text-align: center;
        // }

        .selected-flag {
            width: 50px;
            padding-left: 16px;
        }

        .flag-dropdown {
            border: none;
        }
    }

    .upload {
        width: 120px;
        height: 140px;
        background: skyBlue;
        min-width: 120px;
        margin-top: 10px;
        margin-right: 20px;

        img {
            max-width: 120px;
            max-height: 140px;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 0 !important;
        }
    }

    .crossBtn {
        text-align: center;
        height: 24px;
        width: 24px;
        background: red;
        float: right;
        z-index: 999 !important;
        position: relative;
    }

    // modal=====
    .modal {
        .modal-header {
            padding: 33px 33px 18px 33px;
        }

        .modal-body {
            padding: 0 70px;
            min-height: 340px;

            .isoSelectedTexts{
                width: 20px;
                height: 22px;
                border: 1px dashed #e73d3b;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #e73d3b;
                font-size: 10px;
            }

            .manage_flex {
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;
            }
        }

        .container {
            margin-right: 20px !important;
            width: 120px !important;
            height: 140px !important;
            background-color: #d9d9d9;
            display: inline-flex;
            justify-content: center;
            margin: 0;
            margin-top: 10px;

            .dropzone {
                width: 100%;
                text-align: center;
                font-size: 38px;
                padding-top: 43px !important;
                color: #919191;
                font-weight: 400;
            }
        }

        .dropzone {
            border: none;
        }
    }

    .modal-dialog {
        max-width: 62%;
    }

    .modal-content,
    .modal-header,
    .modal-footer {
        border: none;
        border-radius: 0;
    }

    .modal-footer {
        margin: auto;
        margin-bottom: 48px;
        width: 520px;
        max-width: auto;
    }

    .custom-btn-primary {
        height: 56px;
        width: 100%;
        background: #19c1d6;
        border: none;
        border-radius: 0;
    }

    .totalImg {
        background: red;
        height: 24px;
        width: 24px;
        float: right;
        color: #fff;
        cursor: pointer;
    }


    // table content --------------
    .table_content {
        border: 1px solid #19c1d6;
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 48px;
        

        .table_content_header {
            border-bottom: 1px solid #19c1d6;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .table_content_body {
            padding: 0;

            .table_content_day {
                border-right: 1px solid #19c1d6;
                border-bottom: none;
                padding-left: 12px;
                padding-right: 12px;
                padding-top: 20px;
                padding-bottom: 20px;
                // min-width: 94px;
            }

            .timeValue {
                span {
                    background-color: #F0FDFF;

                    border: 1px solid #b7b7b7 !important;
                    // padding: 6px 18px;
                }

                background-color: #F0FDFF;
                padding-top:20px;
                padding-bottom: 20px;

            }

            .checkb {
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: #F0FDFF;
            }
        }
    }

    .modal2 {
        .modal-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 12px;
            color: #a3a3a3;

        }

        .modal-body {
            max-width: 830px;
            margin-right: 20px;
        }

        .modal-header {
            padding-left: 60px;
        }

        .food_type {
            margin-right: 20px !important;
            margin-top: 28px;

            p {
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                color: #a3a3a3;
                margin-top: 8px;
            }
        }

        .manage_flex {
            justify-content: unset !important;
        }
    }
    .food_type{
        margin-right: 14px;
        flex-wrap: wrap;

        p {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            color: #a3a3a3;
            margin-top: 8px;
            max-width: 94px;
            word-break: break-all;
        }
    }
    .bg-colored{
        background: #F0FDFF;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        border: 1px solid grey;
        margin-top: 7px ;
        margin-bottom: 7px ;
        padding:6px;
        background: #fff;
        color: #000;
        text-align: center;
    }

    .input-group{
        background-color: #f2f2f2;
        border-radius: 4px;
        
        input:focus{
            outline: none;
            border: none;
            box-shadow: none;
        }
        .PhoneInput{
            padding: 20px;
            padding-right: 0;
            width: 100%;
        }
        .PhoneInputInput{
            border: none;
            background-color: #f2f2f2;
        }
    }
    .selectStiker{
        background: #19C1D6;
    }
    .table_content_data{
        .inputTime{
            max-width: 135px;
            margin:auto ;
            
        }
        .TimePicker{
            display: flex;
            align-items: center;
        }
    }
    .without_ampm::-webkit-datetime-edit-ampm-field {
        display: none;
      }
      input[type=time]::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance:none;
        appearance: none;
        margin: -10px; 
      }
      input[type='checkbox'] {
        accent-color: #19c1d6;
        color: #fff;
        cursor: pointer;
    }
  
    .geosuggest{
        .geosuggest__input-wrapper{
            height: 100%;
            width:100%;
        }
        .geosuggest__input{
            width: 100%;
            height: 100%;
            padding: 20px !important;
            background-color: #f2f2f2;
            border: none;
            color: #000000;
            border-radius: 4px !important;
            border: none;
        }

        .geosuggest__input:focus{
            border: none;
        }
        .geosuggest__input::placeholder{
            color: #bcbcbc;
        }
        .geosuggest__suggests-wrapper{
            background-color: #ffffff;
            max-width:38%;
            z-index: 2;
            position:absolute;

            .geosuggest__item{
                padding:5px 15px 5px 0px;
                font-size: 18px;
                list-style: none;
                cursor: pointer;
            }
            .geosuggest__item:hover,
            .geosuggest__item:focus {
                background:#bfe9f1;
            }

        }
        .geosuggest__input:focus, input:focus{
            outline: none;
            border: none;
        }
    }
}

.rw-widget-input {
    background-color:#f2f2f2!important;
    height: 60px;
    box-shadow: none !important;
    border: none !important;
}
.rw-state-focus .rw-widget-picker{
    border-color: transparent !important;
}
.rw-state-focus .rw-widget-picker .rw-widget-input {
    box-shadow: none !important;
}

.reservation_type_list {
    padding: 0px !important;
}

.business-open-date {
    .MuiFormControl-root {
        .MuiInputBase-input {
            border: none;
            background: transparent;
            text-align: unset;
            padding-left: 15px; 
        }
    }
}
