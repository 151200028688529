.Sidebar_control{
    width: 23.54%;
    background: #fff;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 99;
    overflow-y: scroll;
    box-shadow: 0px 6px 12px rgba(228, 228, 228, 0.5);
}
.ppCircle{
    width: 129px;
    height: 129px;
    border: 0.5px solid black;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    img{
        border-radius: 50%;
    }
}
.editBtn{
    background: #E0E0E0;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    position: relative;
    top: -35px;
    left: 80px;
}
.logo_control{
    margin-top: 58px;
}
.admin_name{
    font-family: 'Poppins', sans-serif;
    font-size: 1.625rem !important;
}
.side-navigation-panel{
    margin-top: 92px;
    font-size: 20px !important;
}
.side-navigation-panel-select{
    margin-top: 15px !important;
}

.side-navigation-panel-select-option, .side-navigation-panel-select-option-selected{
    border-left:2px solid transparent !important;
    height: 73px;
    svg {
        height: 20px;
        width: 20px;
    }
}
[class*="option-selected"]{
    border-left:2px solid #19C1D6 !important;
    color: #19C1D6 !important;
    background: #19c0d600 !important;
}
.side-navigation-panel-select-inner-option-text{
    font-size: 20px !important;
    font-family: 'Poppins', sans-serif;
    height: 32px;
    display: flex;
    align-items: center;
}
.side-navigation-panel-select-inner-option{
    height: 73px !important;
}
.side-navigation-panel-select-inner-option:hover{
    border-left: 2px solid #19C1D6 !important;
}
.side-navigation-panel-select-inner-option{
    border-left: 2px solid transparent;
}
.Sidebar_control::-webkit-scrollbar {
    display: none;
}
.sidebar_business{
    display: none;
}
