.navbar{
    height: 100px;
    background: #fff;
    width: 76.46%;
    margin-left: 23.54%;
    .back_btn{
        padding-left:52px;
    }
}
.logout_btn{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding: 12px 24px;
    border-radius: 0;
    background: #19C1D6;
    border: #19C1D6;
    margin-right: 38px;
    img{
        margin-right: 15px;
    }
}