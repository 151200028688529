.input_area_container {
  max-width: 110px;
}
.textEditor {
  .rdw-option-wrapper {
    border: none;
  }
  .rdw-text-align-wrapper,
  .rdw-inline-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-list-wrapper {
    border-right: 0.25px solid #9b9b9b;
    padding-right: 10px;
    padding-left: 10px;
  }
  margin-top: 30px;
  .MuiFormControl-root {
    width: 100%;
  }
  .editor-class {
    padding: 0 20px;
    min-height: 500px;
  }

  .wrapper-class {
    z-index: 99;
  }
  .rdw-editor-toolbar {
    padding: 14px;
    padding-bottom: 9px;
  }
  .editor_container {
    background: #fff !important;
    margin-top: 16px;
  }
  .input_area {
    padding-left: 40px;
    padding-bottom: 27px;
    width: 100%;
    height: 100%;
    margin: auto;
    span {
      max-width: 88px;
      height: 88px;
      background: #ebeced;
      i {
        background: #ebeced;
        border: 1px dashed #9b9b9b;
        padding: 6px 15px;
        font-size: 36px;
      }
    }

    .container {
      .dropzone {
        border: none;
      }
    }
  }

  // mui controlll==========
  .custom_input {
    height: 100%;
    border: none;
    padding-left: 15px;
    width: auto;
  }

  .Row_one {
    .MuiFormControl-root:first-child {
      width: 206% !important;
      margin-left: 0;
    }
    .MuiFormControl-root:last-child {
      margin-right: 0;
    }
  }
  .MuiFormControl-root {
    background-color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .Row_two {
    span {
      color: #9b9b9b !important;
    }
    .MuiFormControl-root:first-child {
      margin-left: 0;
    }
    .MuiFormControl-root:last-child {
      margin-right: 0;
    }
    .colspan_fix {
      width: 216%;
    }
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: center;
  }
  
  .MuiSelect-select {
    span {
      color: #9b9b9b !important;
    }
  }
}
