ul.pagination {
  display: flex;
  list-style: none;
  place-content: end;
  text-decoration: none;
  line-height: 1;
}
ul.pagination li {
  color: black;
  margin-left: 2px;
  margin-right: 2px;
}
ul.pagination li a {
  text-decoration: none;
  color: #000000;
}
.active {
  background-color: #19c1d6 !important;
  color: #ffffff !important;
}
.disable {
  background-color: lightgray !important;
  pointer-events: none;
  cursor: none;
  padding: 15px 15px;
    
}
.pagination li:first-child a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-decoration: none;
  color: #feb808;
}
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  color: #19c1d6;
}
.pagination li:last-child a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-decoration: none;
  color: #feb808;
}