.territories{
    .h-30{
        height: 30px;
    }
    .btn:focus{
        background-color: #19C1D6 !important;
        border-color: #19C1D6 !important;
    }
    .button:focus:not(:focus-visible){
        background-color: #19C1D6 !important;
        border-color: #19C1D6 !important;
    }
    .content_area{
        .operational_area{
            color: #A3A3A3;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            font-size: 1.125rem;
            margin-bottom: 24px;
        }
    }

    .content_block{
        margin-bottom: 120px ;
    }

    .bar{
        justify-content: space-between;
        background: #fff;
        padding:22px 32px;
        margin-bottom: 16px;

        .flag{
            height: 39px;
            width: 61px;
        }
        img{
            margin-right: 31px;
        }
        p{
            font-weight: 500;
            font-family: 'Poppins', sans-serif;
            font-size: 1.125rem;
        }
        // ------remove inlineblock
        span{
            display: inline-block;
            align-self: center;
        }
        svg{
            color: #9AABB5;
        }
    }
    .modal-dialog{
        min-width: 73.68% !important;
        margin-right: 18px !important;
        
    }
    .modal-content{
        border-radius: 0;
        padding: 0 34px;
        margin-right:150px;

        .modal-header{
            .btn-close {
                text-align: center;
                height: 24px;
                width: 24px;
                float: right;
                z-index: 999 !important;
                position: relative;
                cursor: pointer;
            }
        }
    }
    .modal{
        .modal-title{
            margin-top: 75px;
            color: #19C1D6;
            font-size: 1.75rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }
        .middel_body{
            margin-top: 85px;
            margin-bottom: 85px;
        }
        .btn{
            padding: 15px 108px;
            border-radius: 0;
            background: #19C1D6;
            font-family: 'Poppins', sans-serif;
            font-size: 1.125rem;
            font-size: 400;
            border: #19C1D6;
            margin-bottom: 71px;
        }
        .MuiSelect-select{
            background: #f2f2f2;
        }
        .MuiOutlinedInput-root{
            border-radius:0 ;
        }
        .MuiInputLabel-root{

            color: #bcbcbc !important;
        }
        .MuiOutlinedInput-notchedOutline{
            border-color: #f2f2f2;
        }
        .MuiAccordionDetails-root{
            display: none !important;
        }
    }
    .city_text{
        color: #A3a3a3 !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
    }
    .MuiAccordionDetails-root {
        border-top: 4px solid #e0e0e0 !important;
        padding: 19px 16px 10px 16px;
    }

    .MuiSwitch-thumb{
        background-color: #858585;
        height: 22px;
        width: 22px;
        margin-left: 8px;
        margin-top: 6px;
    }
    .Mui-checked{
        
        .MuiSwitch-thumb{
            background-color: #5ba30a;
            height: 22px;
            width: 22px;
            margin-left: 9px;
            margin-top: 6px;
        }
    }
    .Mui-checked+span{
        background-color: #96d156 !important;
    }
    .MuiSwitch-track{
       height: 29px;
       border-radius: 50px;
       background-color: #a8a7a7;
    }
    .MuiSwitch-root{
        width: 76px;
        height: 50px;
    }
    .accordion-city-data{
        padding: 5px 0;
    }

}

// =========
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#EDEDED;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #60A511;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #CFE4B8;
  }

input:not(:checked) + .slider:before {
    background-color: #858585;
}
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .modal{
    background: #00000039;
    z-index: 98;
  }
  .MuiSelect-select{
    span{
        color: #9B9B9B !important;
    }
}
.MuiSelect-select{
    span{
        color: #9B9B9B !important;
    }
}

.MuiOutlinedInput-notchedOutline{
    border: none !important;
}