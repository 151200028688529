.campaign {
  .post_header {
    color: #bcbcbc;
    padding-left: 28px;
  }
  .post_data {
    margin-top: 80px;
  }
  .heading_post {
    padding: 0 90px;
    color: #979797;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
  .post_card {
    background: #fff;
    padding: 28px 24px;
    color: #979797;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin: 12px 0;

    .weeks {
      .days {
        border: none;
        color: #000000;
        text-align: center;
        background-color: #19c1d6;
        padding: 2px 10px;
        margin-right: 10px;
        font-weight: 400;
      }
      .daysColor {
        background-color: #19c1d6;
        color: #fff;
      }
    }
  }
  .MuiSwitch-thumb {
    background-color: #858585;
    height: 22px;
    width: 22px;
    margin-left: 8px;
    margin-top: 6px;
  }
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: #5ba30a;
      height: 22px;
      width: 22px;
      margin-left: 9px;
      margin-top: 6px;
    }
  }
  .Mui-checked + span {
    background-color: #96d156 !important;
  }
  .MuiSwitch-track {
    height: 29px;
    border-radius: 50px;
    background-color: #a8a7a7;
  }
  .MuiSwitch-root {
    width: 76px;
    height: 50px;
  }
  .icon_custom {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .modal {
    z-index: 98;
    background: #00000039;
    .selected {
      color: #19c1d6;
    }
    .unselect {
      color: #a3a3a3;
    }
    margin-left: 10.61%;
    .btn-close {
      padding-top: 50px;
      padding-right: 60px;
    }
    .modal-dialog {
      max-width: 761px !important;
    }
    .modal-content {
      width: 761px !important;
      max-width: 761px !important;
      border-radius: 0;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem;
      font-weight: 500;
      letter-spacing: 0px;
      margin-left: 16px;
    }
    .modal-body {
      margin-bottom: 0px;
    }
  }
  .post_type {
    padding: 32px 0 26.75px 0;
    margin-left: 68px;
    margin-right: 45px;
    border-bottom: 2px solid #e0e0e0;
  }
  .icon_back {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fadeLinked {
    background: url("../../assets/images/campaign/link.png");
  }
  .fadeAssociation {
    background: url("../../assets/images/campaign/association.png");
  }
  .post_type:hover {
    .fadeLinked {
      background: url("../../assets/images/campaign/linkColor.png");
    }
    .fadeAssociation {
      background: url("../../assets/images/campaign/associationColor.png");
    }
    p {
      color: #19c1d6;
    }
  }
  .icon_back:hover {
    background-color: #19c1d6;
  }
}

