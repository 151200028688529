label {
    color: #9d9a9a !important;

}
.CommonForm {

    .container{
        cursor: pointer;
    }
    .iconmap{
        cursor: pointer;
    }
    font-family: 'Poppins', sans-serif;
    padding: 57px 38px;
    margin-top: 62px;
    
    input:focus, textarea:focus, select:focus{
        outline: none;
    }
    .merge_input_first{
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        input{
            width: 100%;
        }
        input::placeholder{
            color: #bcbcbc;
            opacity: 1;
        }
    }
    .merge_input_second{
        .MuiFormControl-root{
            div {
                justify-content: center;
            }
        }
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .input_picker{
            height: 40px;
            border-left: 1px solid rgba(0, 0, 0, 0.5) !important;
            border-radius: 0 !important;
            width: 100%;
        }

        input::placeholder{
            color: #bcbcbc;
            opacity: 1;
        }
        input:focus{
            border: none;
        }
    }
    .MuiFilledInput-root {
        border-radius: 4px;
    }

    .MuiFilledInput-root::before {
        border-bottom: none;
    }

    .MuiFilledInput-root::before {
        border: none !important;
    }

    MuiInputBase-root.MuiFilledInput-root.MuiSelect-root:after {
        border-bottom: none;
    }

    .MuiInputLabel-root {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;

    }

    .MuiSvgIcon-root {
        color: #bcbcbc;
        margin-right: 10px;
    }

    .MuiFormControl-root {
        border: none !important;

    }

    .MuiFormControl-root, .MuiFormControl-root{
        margin: 0;
    }
    .input_area {
        margin-bottom: 20px;
        .MuiOutlinedInput-notchedOutline{
            border: none !important;
        }
        .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
            background-color: #f2f2f2;

        }

    .isoSearchSelection{
        overflow: scroll;
        overflow-x: hidden;
        max-height:300px;
    }

        .MuiSelect-select{
            span{
                color: #9B9B9B !important;
    
    
            }
        }
        .input-group{
            background-color: #f2f2f2;
            .input-group-append{
                width: 50px;
            }
        }
        input,
        textarea,
        select {
            background: #f2f2f2;
            border: none;
            padding: 20px 30px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            box-shadow: none;
        }

        select {
            color: #bcbcbc;
        }

        $placeholder: (
            '::-webkit-input-placeholder',
            ':-moz-placeholder',
            '::-moz-placeholder',
            ':-ms-input-placeholder',
            '::input-placeholder',
            '::placeholder'
        );

    @each $selector in $placeholder {
        #{$selector} {
            font-size: 1em;
            color: #bcbcbc;
            padding: 5px;
        }
    }


    .isoTagSelectClass {
        position: relative;
        box-sizing: border-box;
        padding:0 !important;

        .localiso__control {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            min-height: 38px;
            background: rgb(242, 242, 242);
            height: 60px;
            border:none !important;
            outline: none !important;
            box-shadow: none !important;
            padding: 0px 30px;

            .localiso__placeholder {
                font-size: 14px;
                font-weight: 400;
                font-family: "Poppins", sans-serif;
                color: #9d9a9a;
            }
        }
        .localiso__control:hover{
            border-color: none !important;
            outline: none !important;
            box-shadow: none !important;
        }  
    }
}

.dropzone_custom {
    background: red;
    height: 190px;
    width: 452px;
}

.searchOrder {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20%;
    max-width: 250px;
    height: 20%;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
}

.dropzone_heading {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    color: #bcbcbc;

}

.dropzone {
    border: 1px dashed #a3a3a3;
    border-radius: 8px;
    padding: 62px 0;
    font-family: 'Poppins', sans-serif;

    p {
        font-size: 0.875rem;
        color: #858585;
        font-weight: 500;

        div {
            display: inline;
            color: #19C1D6;
            font-weight: bold;
        }

    }

    span {
        font-size: 0.75rem;
        color: #858585;
        font-weight: 300;
    }

}
.select_days{
    color: #bcbcbc;
}
.week{
    .day{
        border: 1px solid #C8CAC7;
        color: #858585;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
    }
    .localColor{
        background-color: #19C1D6;
        color: #fff;
    }
}
.upload_img_field{
    height: 190px;
    img{
        border-radius: 12px;
        border: 1px solid #C8CAC7 ;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
    }
}

}


.datepicker{
    padding-left: 12px;
    padding-right: 12px;
}

// {DropZone}

.dropzone_custom {
    background: red;
    height: 190px;
    width: 452px;
}

.searchOrder {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20%;
    max-width: 250px;
    height: 20%;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
}

.dropzone_heading {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    color: #bcbcbc;

}

.dropzone {
    border: 1px dashed #a3a3a3;
    padding: 62px 0;
    font-family: 'Poppins', sans-serif;

    .update_drop {
        background: #d9d9d9;
        max-width: 120px;
        min-height: 140px;
        margin: 25px 30px;
        font-size: 48px;
        color: #919191;
    }

    p {
        font-size: 0.875rem;
        color: #858585;
        font-weight: 500;

        div {
            display: inline;
            color: #19C1D6;
            font-weight: bold;

        }
    }

    span {
        font-size: 0.75rem;
        color: #858585;
        font-weight: 300;
    }
}

.select_days {
    color: #bcbcbc;
}

.week {
    .day {
        border: 1px solid #C8CAC7;
        color: #858585;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
    }

    .localColor {
        background-color: #19C1D6;
        color: #fff;
    }
}

.upload_img_field {
    height: 190px;

    img {
        border-radius: 12px;
        border: 1px solid #C8CAC7;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
    }
}

.validate {
    color: #19C1D6 !important;
}

.react-tel-input {
    .form-control {
        padding-left: 60px;
    }

    .selected-flag {
        width: 50px;
        padding-left: 16px;
    }

    .flag-dropdown {
        border: none;
    }
}

.upload {
    width: 120px;
    height: 140px;
    background: skyBlue;
    min-width: 120px;
    margin-top: 10px;
    margin-right: 20px;

    img {
        max-width: 120px;
        max-height: 140px;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 0 !important;
    }
}

.crossBtn {
    text-align: center;
    height: 24px;
    width: 24px;
    background: red;
    float: right;
    z-index: 999 !important;
    position: relative;
    cursor: pointer;
}


// modal=====

.modal {
    .modal-header {
        padding: 33px 33px 18px 33px;
    }

    .modal-body {
        padding: 0 70px;
        min-height: 340px;

        .manage_flex {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
        }
    }

    .modal-content {
        position: relative;
        display: flex;
        margin-left: 150px;
        flex-direction: column;
        width: 100%;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: var(--bs-modal-bg);
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        outline: 0;
    }

    .container {
        margin-right: 20px !important;
        width: 120px !important;
        height: 140px !important;
        background-color: #d9d9d9;
        display: inline-flex;
        justify-content: center;
        margin: 0;
        margin-top: 10px;

        .dropzone {
            width: 100%;
            text-align: center;
            font-size: 38px;
            padding-top: 43px !important;
            color: #919191;
            font-weight: 400;
        }
    }

    .dropzone {
        border: none;
    }

}

.modal-dialog {
    max-width: 62%;
}

.modal-content,
.modal-header,
.modal-footer {
    border: none;
    border-radius: 0;
}

.modal-footer {
    margin: auto;
    margin-bottom: 48px;
    width: 520px;
    max-width: auto;
}

.totalImg {
    background: red;
    height: 24px;
    width: 24px;
    float: right;
    color: #fff;
}

.css-b62m3t-container{
    padding: 0 !important;
}
