.post{
    .post_header{
        color: #bcbcbc;
        padding-left: 28px;
    }
    .post_data{
        margin-top: 80px;
    }
    .heading_post{
        padding: 0 90px;
        color: #979797;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
    }
    .post_card{
        background: #fff;
        padding: 28px 24px;
        color: #979797;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        margin: 12px 0;
        .businessName{
            padding-left: 7px !important;
        }
    }
    .MuiSwitch-thumb{
        background-color: #858585;
        height: 22px;
        width: 22px;
        margin-left: 8px;
        margin-top: 6px;
    }
    .Mui-checked{
        .MuiSwitch-thumb{
            background-color: #5ba30a;
            height: 22px;
            width: 22px;
            margin-left: 9px;
            margin-top: 6px;
        }
    }
    .Mui-checked+span{
        background-color: #96d156 !important;
    }
    .MuiSwitch-track{
       height: 29px;
       border-radius: 50px;
       background-color: #a8a7a7;
    }
    .MuiSwitch-root{
        width: 76px;
        height: 50px;
    }
    .icon_custom{
        img{
            width: 20px;
            height: 20px;
        }
    }
    .modal{
        z-index: 98;
        background: #00000039;
        .selected{
            color: #19C1D6;
        }
        .unselect{
            color: #a3a3a3;
        }
        margin-left: 10.61%;
        .btn-close{
            padding-top: 50px;
            padding-right: 60px;
        }
        .modal-dialog{
            max-width: 761px !important;
        }
        .modal-content{
            width: 761px !important;
            max-width: 761px !important;
            border-radius: 0;
        }
        p{
            font-family: 'Poppins', sans-serif;
            font-size: 1.75rem;
            font-weight: 500;
            letter-spacing: 0px;
            margin-left: 16px;
        }
        .modal-body{
            margin-bottom: 51px;
        }
    }
    .post_type{
        padding: 32px 0 26.75px 0;
        margin-left: 68px;
        margin-right: 45px;
        border-bottom: 2px solid #E0E0E0;
    }
    .icon_back{
        border-radius: 50%;
        height: 50px;
        width: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fade_event{
        background: url('../../assets/images/postModal/eventfade.png');
    }
    .fade_update{
        background: url('../../assets/images/postModal/updatefade.png'); 
    }
    .fade_special{
        background: url('../../assets/images/postModal/specialfade.png'); 
    }
    .post_type:hover{
        .fade_event{
            background: url('../../assets/images/postModal/eventsky.png');
        }
        .fade_update{
            background: url('../../assets/images/postModal/updatesky.png'); 
        }
        .fade_special{
            background: url('../../assets/images/postModal/specialsky.png'); 
        }
        p{
            color: #19C1D6;   
        }
    }
    .icon_back:hover{
        background-color: #19C1D6 ;
    }
}

