body {
    overflow: unset !important;
    padding-right: 0px !important;
}

.content_area{

    background: #EDEDED;
    margin-left: 23.54%;
    height: 88vh !important;
    padding: 63px 38px;
    overflow: hidden;
    height:80vh;
    overflow-y: scroll;

    .commom_btn {
        &:focus {
            background-color: #19C1D6;
            border-color: #19C1D6;
            box-shadow: none;
        }
        &:hover {
            color: #fff;
            background-color: #0b5ed7;
            border-color: #0a58ca;
            box-shadow: 0 0 0 0.25rem rgba(49,132,253, 0.5);
        }
    }

    .MuiSwitch-root {
        .MuiSwitch-switchBase {
            &:hover {
                background-color: rgba(25, 118, 210, 0.00);
            }
        }
    }
}
.content_block{
    font-size: 2.125rem;
    font-family: 'Poppins', sans-serif;
    svg {
        color: #19C1D6;
        margin-bottom: 5px;
        font-size: 20px;
    }
    p {
        color: #19C1D6;
        padding-left: 16.58px;
    }
}
.commom_btn{
    background-color: #19C1D6;
    border-color: #19C1D6;
    border-radius: 0;
    padding: 12px 42px;
    max-height: 55px;
    font-size: 1.25rem;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    border: none;
    width: 224px;
}

.bi-plus-circle{
    font-size: 30px;
    font-weight: bold;   
}
.bi-clock{
    font-size: 30px;
    font-weight: bold;   
}
.content_area{
    .bi-plus-circle{
        color: #19C1D6;
    }
    .bi-clock{
        color: #19C1D6;
    }
}
.local-text{
    color: #19C1D6;
    
}
