.notification {

    .heading {
        color: #19C1D6;
        font-weight: 400;
        font-size: 34px;
        margin: 0px 0px 0px 10px;
        padding: 0px;

    }

    .post_card {
        background: #fff;
        padding: 28px 24px;
        color: #979797;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        margin: 12px 0;

        .NotificationTitle {
            border-radius: 4px;
            height: 41px;
            width: 100%;
            border: 1px solid #f2f2f2;
            padding: 0 15px 0 15px;
            background-color: #f2f2f2;
        }

        .custom_dropdown {
            width: 100%;

            .NotificationTitles {
                border-radius: 4px;
                width: 100%;
                border: 1px solid #f2f2f2;
                outline: none;
                background-color: #f2f2f2;
            }

            #search-select-label {
                margin-top: -5px;
            }

            .form-control {
                border-radius: 4px;
                height: 41px;
                width: 100%;
                border: 1px solid #f2f2f2;
                margin: 0px;
                padding: 0px;
                background-color: #f2f2f2;

            }

            .DropdownData {
                align-items: center;
                display: flex;
            }

        }
    }

    .textEditor {
        margin-top: 0px;

        .rdw-option-wrapper {
            border: none;
        }

        .rdw-text-align-wrapper,
        .rdw-inline-wrapper,
        .rdw-fontfamily-wrapper,
        .rdw-list-wrapper {
            border-right: 0.25px solid #9B9B9B;
            padding-right: 10px;
            padding-left: 10px;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .editor-class {
            padding: 0 20px;
            min-height: 500px;
            overflow: scroll;
            max-height: 500px;
            overflow-x: unset;
            background: #F4F4F4;
        }

        .wrapper-class {
            z-index: 99;
        }

        .rdw-editor-toolbar {
            background: #F4F4F4;
            width: 100%;
            height: 40px;
            padding: 0px;
            margin-bottom: 15px;
        }

        .rdw-inline-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            border-right: none;
        }

        .rdw-embedded-modal-header-option {
            width: 80%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .rdw-list-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            border-right: none;
        }

        .rdw-text-align-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            border-right: none;
        }

        .rdw-colorpicker-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-link-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-embedded-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-emoji-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-image-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-remove-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            position: relative;
            flex-wrap: wrap;
        }

        .rdw-history-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
        }

        .rdw-option-wrapper {
            // border: 1px solid #F1F1F1;
            padding: 13px;
            min-width: 25px;
            height: 20px;
            border-radius: 2px;
            margin: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: #F4F4F4;
            text-transform: capitalize;
        }

        .rdw-dropdown-selectedtext {
            display: flex;
            position: relative;
            height: 100%;
            align-items: center;
            padding: 0 5px;
            margin: 0px
        }

        .rdw-dropdown-selectedtext {
            display: flex;
            position: relative;
            height: 100%;
            align-items: center;
            padding: 0 5px;
            color: #19C1D6;
            text-decoration: none !important;
            margin: 0px;
        }

        .rdw-block-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            background: white;

        }

        .rdw-fontsize-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            background: white;
            border: 1px solid #F4F4F4;
        }

        .rdw-fontfamily-wrapper {
            display: flex;
            align-items: center;
            margin: 0px;
            flex-wrap: wrap;
            border-right: none;
            background: white;
        }

        .rdw-dropdown-wrapper {
            height: 30px;
            background: white;
            cursor: pointer;
            border: none;
            border-radius: 2px;
            margin: 0 3px;
            text-transform: capitalize;
            background: white;
        }

        .editor_container {
            background: #fff !important;
            // margin-top: 16px;
        }

        .input_area {
            padding-left: 40px;
            padding-bottom: 27px;
            width: 100%;
            height: 100%;
            margin: auto;

            span {
                max-width: 88px;
                height: 40px;
                background: #EBECED;

                i {
                    background: #EBECED;
                    border: 1px dashed #9B9B9B;
                    padding: 6px 15px;
                    font-size: 36px;
                }
            }

            .container {
                .dropzone {
                    border: none;
                }
            }
        }

        // mui controlll==========

        .custom_input {
            height: 100%;
            border: none;
            padding-left: 15px;
            width: auto;
        }

        .Row_one {

            .MuiFormControl-root:first-child {
                width: 206% !important;
                margin-left: 0;
            }

            .MuiFormControl-root:last-child {
                margin-right: 0;
            }
        }

        .MuiFormControl-root {
            background-color: #fff;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .Row_two {
            span {
                color: #9B9B9B !important;
            }

            .MuiFormControl-root:first-child {
                margin-left: 0;
            }

            .MuiFormControl-root:last-child {
                margin-right: 0;
            }

            .colspan_fix {
                width: 216%;
            }
        }

        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            text-align: center;
        }

        .MuiSelect-select {
            span {
                color: #9B9B9B !important;
            }
        }
    }

    .button {
        color: #FFFFFF;
        background: #19C1D6;
        border: none;
        border-radius: 0px;
        height: 55px;
        width: 223.69px;
        font-size: 20px;
        font-family: "Poppins";
    }

    .react-input-emoji--container {
        height: 40vh !important;
        border-radius: 0 !important;
        font-size: 18px !important;

        .react-input-emoji--placeholder {
            visibility: hidden !important;
        }

        .react-input-emoji--input {
            max-height: none !important;
            height: 40vh !important;
            // overflow-y: scroll;


        }
    }

}

::-webkit-scrollbar {
    display: none;
}