$baseColor: #19C1D6;
.checkmail{
    overflow: hidden;
    .input_field{
        max-width: 510px !important;
        margin-top: 176px;
        margin-bottom: 96px;
    }
    .local-text{
        font-size: 2rem;
        font-family: Raleway_bold;
        color: #000 !important;
        margin-top: 26px;
    }
    .Fp_description{
        p{
            font-size: 1.25rem;
            font-family: Raleway_light;
            max-width: 664px;
            display: block !important;
            margin-top: 32px;
        }
    }

    .content-block{
        margin-top: 80px;
    }
    .checkmail_img{
        max-width: 120px;
    }
    .footer-content{
        display: flex;
        justify-content: center;
        p{
            max-width: 400px;
            margin: auto;
            position: absolute;
            bottom: 68px;
            font-size: 1rem;
            font-family: Raleway_semibold;
            color: #656565;
        }
    }
}