.updatePhoto {
    width: 199px;
    height: 104px;
    margin-bottom: 30px;
}
.claim-business{
    background-color: #fff;
    width: 100%;
    padding:0 30px 0 30px ;
}
.tabList{
    width: 150px;
    height: 44px;
    border:1px solid #bcbcbc;
    color: #bcbcbc;
}
.tabsStyle1{
    padding-left: 120px;
}
.tabsStyle2{
    padding-left: 262px;
}


