.css-fx0ivt[aria-selected="true"],
.css-fx0ivt[data-selected] {
  border: none;
}
.post_header {
  color: #bcbcbc;
  padding-left: 28px;
}

.post_card {
  background: #fff;
  color: #979797;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 12px 0;
  .businessName {
    padding-left: 7px !important;
  }
}
.post_card1 {
  background: #fff;
  color: #979797;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 12px 0;
  border-left: 6px solid green;
  .businessName {
    padding-left: 7px !important;
  }
}

.post_card2 {
  background: #fff;
  color: #979797;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 12px 0;
  border-left: 6px solid red;
  .businessName {
    padding-left: 7px !important;
  }
}
.post_card3 {
  background: #fff;
  color: #979797;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 12px 0;
  border-left: 6px solid #ebf70a;
  .businessName {
    padding-left: 7px !important;
  }
}

.tabList {
  width: 150px;
  height: 44px;
  border: 1px solid #bcbcbc;
  color: #bcbcbc;
}
.tabList1 {
  width: 150px;
  height: 44px;
  color: white;
  background: "#19C1D6";
}
.paginations {
  display: flex;
  justify-content: right;

  .MuiPagination-ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px;
    margin: 0;
    list-style: none;
  }

  .MuiPaginationItem-root.Mui-selected {
    color: black;
    border: 1px solid rgba(25, 118, 210, 0.5);
    background: #19c1d6;
  }

  .MuiPaginationItem-root {
    background-color: white;
    cursor: pointer;
    border-radius: 0;
  }
}

.paginations {
  margin-top: 20px;
  .active {
    background-color: #19c1d6;
  }
  .active a {
    color: white;
  }
  ul li {
    padding: 10px;
    margin-left: 5px;
    border: 1px solid #19c1d6;
  }
  ul li a {
    color: black;
  }
}
.paginations ul li a {
  text-decoration: none !important;
}
