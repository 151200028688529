.card{
    max-width: 100%;
    padding-top: 50px;
    padding-bottom: 32px;
    padding-left: 47px;
    border-radius: 30px;
    border: none;
    min-height: 304px;
    .card_peragraph{
        margin-top: 20px;
        color: #9B9B9B;
        font-size: 18px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        max-width: 415px;
    
    }
    .area{
        margin-top: 30px;
    }
    .territories{
        color: #19C1D6;
    
    }
}