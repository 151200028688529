$baseColor: #19C1D6;
.login{
    background-color: #fff;

    .bannerSide{
       justify-content: center;
        .upper {
            position: absolute;
            z-index: 2;
            margin-top: 45vh;
            margin-bottom: 45vh;
        }
        .banner{
            background-size: cover;
            height: 100vh;
            width: 100%;
            z-index: 0;
        }
        .slick-dots{
            bottom: 15px !important;
            z-index: 1;
        }
        .slick-dots li button:before{
            opacity: 0.75;
            color: grey;
            font-size: 14px;
            height: 12px !important;
        }
        .slick-dots li.slick-active button:before {
            color:White !important;
            font-size: 30px;
            margin-top: 3.4px;
            content: "";
            background: #fff;
            width: 100%;
            border-radius: 40px;
            height: 12px !important;
        }
        .slick-next{
            display: none !important;
        }
        .slick-dots{
            display: inline-block;
            text-align: end;
            padding: 40px 40px 40px 0;
            li{
                margin: 0;
            }
            li.slick-active{
                width: 60px;
            }
        }
    }

    .form-side{
        
        max-width: 35.41%;
        .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
            padding: 10px 30px;
        }
        .css-1x51dt5-MuiInputBase-input-MuiInput-input{
            padding: 18px 30px;
            font-size: rem;
            font-family: Raleway_semibold;
        }
        .email_field{
            label{
                color: #BCBCBC;
            }
        }
        .password_field{
            margin-top: 10px;
            label{
                color: #BCBCBC;
            }
        }
        .checkbox_field{
            .checbox_circle{
                color: $baseColor;
            }
            margin-top: 35px;
            label{
                font-size: 0.875rem;
                color: #BCBCBC;
                ;
            }
        }
        .continue_button{
            margin-top: 84px;
            height: 70px;
            max-width: 100%;
            font-size: 1.5rem;
            font-family: Raleway_Semibold;
            border-radius: 0;
            background: $baseColor;
            border-color: $baseColor;

        }
        h3{
            color: $baseColor;
            margin-bottom: 120px;
            text-align: center;
            font-size: 2rem;
            font-family: Raleway_Bold;

        }
        .forgetPassword{
            color: $baseColor;
            font-size: 0.875rem;
            cursor: pointer;
        }
    }
}

[type*="error"] {
    width:425px;
    max-width: 600px;
}