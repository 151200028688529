.businesses {

    .post_header {
        color: #bcbcbc;
        padding-left: 28px;
        padding-right: 28px;
    }

    .post_data {
        margin-top: 80px;
    }

    .heading_post {
        padding: 0 90px;
        // color: #979797;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
    }

    .post_card {
        background: #fff;
        padding: 28px 24px;
        color: #979797;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        margin: 12px 0;

        .businessName {
            padding-left: 7px !important;
        }
    }

    .MuiSwitch-thumb {
        background-color: #858585;
        height: 22px;
        width: 22px;
        margin-left: 8px;
        margin-top: 6px;
    }

    .Mui-checked {
        .MuiSwitch-thumb {
            background-color: #5ba30a;
            height: 22px;
            width: 22px;
            margin-left: 9px;
            margin-top: 6px;
        }
    }

    .Mui-checked+span {
        background-color: #96d156 !important;
    }

    .MuiSwitch-track {
        height: 29px;
        border-radius: 50px;
        background-color: #a8a7a7;
    }

    .MuiSwitch-root {
        width: 76px;
        height: 50px;
    }

    .icon_custom {
        img {
            width: 20px;
            height: 20px;
        }
    }

    .modal {
        z-index: 98;
        background: #00000039;

        .selected {
            color: #19C1D6;
        }

        .unselect {
            color: #a3a3a3;
        }

        margin-left: 10.61%;

        .btn-close {
            padding-top: 50px;
            padding-right: 60px;
        }

        .modal-dialog {
            max-width: 761px !important;
        }

        .modal-content {
            width: 761px !important;
            max-width: 761px !important;
            border-radius: 0;
        }

        p {
            font-family: 'Poppins', sans-serif;
            font-size: 1.75rem;
            font-weight: 500;
            letter-spacing: 0px;
            margin-left: 16px;
        }

        .modal-body {
            margin-bottom: 51px;
            min-height: auto;
            padding-bottom: 30px;
        }
    }

    .post_type {
        padding: 32px 0 26.75px 0;
        margin-left: 68px;
        margin-right: 45px;
        border-bottom: 2px solid #E0E0E0;
    }

    .icon_back {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fade_event {
        background: url('../../assets/images/postModal/eventfade.png');
    }

    .fade_update {
        background: url('../../assets/images/postModal/updatefade.png');
    }

    .fade_special {
        background: url('../../assets/images/postModal/specialfade.png');
    }

    .post_type:hover {
        .fade_event {
            background: url('../../assets/images/postModal/eventsky.png');
        }

        .fade_update {
            background: url('../../assets/images/postModal/updatesky.png');
        }

        .fade_special {
            background: url('../../assets/images/postModal/specialsky.png');
        }

        p {
            color: #19C1D6;

        }
    }

    .icon_back:hover {
        background-color: #19C1D6;

    }

    .content_block {
        margin-bottom: 80px;
    }

    .modal {
        .commonBtn {
            max-width: 230px;
            background: #19C1D6;
            border: 0;
            border-radius: 0;
            color: #fff;
            padding: 12px 40px;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 36px;
        }

        .modal-body {
            input {
                border: 1px solid #19C1D6;
                height: 60px;
                max-width: 462px;
                margin: auto;
                padding: 0 18px;
                font-size: 1.25rem;
            }
        }
    }

    .commom_btns {
        background-color: #19C1D6;
        border-color: #19C1D6;
        border-radius: 0;
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        color: #fff;
        border: none;
        width: 224px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:focus {
            background-color: #19C1D6;
            border-color: #19C1D6;
            box-shadow: none;
        }

        &:hover {
            color: #fff;
            background-color: #0b5ed7;
            border-color: #0a58ca;
            box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
        }
    }
}

.customerFileBtn #inputGroupFile {
    color: #007bff;
    font-weight: 600;
}

.customerFileBtn .input-group-text {
    background-color: #19C1D6;
    color: #ffffff !important;
    font-weight: 600;
}

.customerFileBtn {
    width: 450px;
    // width: 50%;
    margin: auto;
}

input[type='file'] {
    background-color: transparent;
}

.customerFileBtn input[type='file'] {
    padding: 6px 0 0 7px;

    &::-webkit-file-upload-button {
        display: none;
    }

    &::file-selector-button {
        display: none;
    }
}

.customerFileBtn .input-group-text {
    border: unset !important;
    border-radius: unset;
    padding-top: 5px;
}

.customerFileBtn #inputGroupFile {
    outline: unset;
    border-radius: unset;
}
.upload-btn{
    background: #19c1d6;
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    width: 270px !important;
    padding: 12px 40px;

    &:hover {
        color: #fff;
        background-color: #19c1d6;
    }
}

.geosuggest__input:focus {
    border: none;
    outline: none;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.csv-file-upload-wrapper {
    color: #181A18;
    height: 60px;
    border: 1px solid #181A18;
    font-family: MrEavesXLSanNarOT-Reg;
    font-size: 24px;
    position: relative;
    width: 100%;
    height: 60px;

    &:after {
        font-family: MrEavesXLSanNarOT-Reg;
        content: attr(data-text);
        font-size: 24px;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        padding: 5px 30px;
        display: block;
        width: calc(100% - 40px);
        pointer-events: none;
        z-index: 20;
        height: 58px;
        line-height: 50px;
        color: #181A18;
    }

    &:hover {
        &:before {
            background: #F2F2F2;
        }
    }

    input {
        font-family: MrEavesXLSanNarOT-Reg;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 58px;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
    }
}

.paginations {
    display: flex;
    justify-content: right;

    .MuiPagination-ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0px;
        margin: 0;
        list-style: none;
    }

    .MuiPaginationItem-root.Mui-selected {
        color: black;
        border: 1px solid rgba(25, 118, 210, 0.5);
        background: #19C1D6;
    }

    .MuiPaginationItem-root {
        background-color: white;
        cursor: pointer;
        border-radius: 0;
    }
}

.paginations {
    margin-top: 20px;

    .active {
        background-color: #19C1D6;
        ;
    }

    .active a {
        color: white;
    }

    ul li {
        padding: 10px;
        margin-left: 5px;
        border: 1px solid #19C1D6;
    }

    ul li a {
        color: black;
    }
}

.paginations ul li a {
    text-decoration: none !important;

}

.business-search {
    .input-group {
        input {
            border-right: 0px;

            &:focus {
                border-color: #ced4da;
                box-shadow: none;
            }
        }

        .input-group-append {
            width: 50px;
            background: #fff;
            border: 1px solid #ced4da;
            border-left: 0px;
            border-radius: 0 0.375rem 0.375rem 0;

            img {
                width: 24px;
            }
        }
    }
}
