body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EDEDED;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
  font-family: "Raleway_SemiBold";
  src: url("./assets/fonts/Raleway-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway_Bold";
  src: url("./assets/fonts/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway_Regular";
  src: url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway_Light";
  src: url("./assets/fonts/Raleway-Light.ttf") format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');