.dashboard {
    .card-margin {
        margin-top: 31px;
    }
    .bi-gear {
        font-size: 30px;
        font-weight: bold;
    }
    .card {
        cursor: pointer;
    }
}