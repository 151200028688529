$baseColor: #19C1D6;
.forgetPassword{
    overflow: hidden;
    .input_field{
        max-width: 510px !important;
        margin: auto;
        margin-top: 80px;
        margin-bottom: 96px;
    }
    .continue_button{
        height: 70px;
        max-width: 510px;
        font-size: 1.5rem;
        font-family: Raleway_Semibold;
        border-radius: 0;
        background: $baseColor;
        border-color: $baseColor;

    }
    .local-text{
        font-size: 2rem;
        font-family: Raleway_bold;
    }
    .Fp_description{
        p{
            font-size: 1.5rem;
            font-family: Raleway_light;
            max-width: 664px;
            display: block !important;
            margin-top: 40px;
        }
    }
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
        padding: 10px 30px;
    }
    .css-1x51dt5-MuiInputBase-input-MuiInput-input{
        padding: 18px 30px;
        font-size: rem;
        font-family: Raleway_semibold;
    }
    .email_field{
        label{
            color: #BCBCBC;
        }
    }
    .content-block{
        margin-top: 100px;
    }
}