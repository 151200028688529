.additionalInformation {
	.content-body {
		font-family: 'Poppins', sans-serif;
	    padding: 57px 38px;
	    margin-top: 62px;
    
		.input_area {
		    background-color: #f2f2f2;
    		border-radius: 4px;

    		.form-control {
    			background: #f2f2f2;
			    border: none;
			    width: 100%;
			    height: 60px;
			    color: #000000;
			    padding: 20px;
			    border-radius: 4px !important;
    		}
		}
	}
}